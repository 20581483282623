import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanLiteral } from 'typescript';
import { TenantService } from '../../pages/tenant/services/tenant.service';
import { TenantComponent } from '../../pages/tenant/tenant/tenant.component';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  isPartherAccess: boolean;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '', isPartherAccess: true  },
  { path: '/tenants', title: 'Tenants', icon: 'fas fa-users text-blue', class: '', isPartherAccess: true },
  { path: '/plans', title: 'Plans', icon: 'ni-planet text-blue', class: '', isPartherAccess: false },
  { path: '/codes', title: 'App Codes', icon: 'ni-planet text-blue', class: '', isPartherAccess: false },
  { path: '/codehistory', title: 'App Code History', icon: 'ni-planet text-blue', class: '', isPartherAccess: false },
  { path: '', title: 'Logout', icon: 'fas fa-sign-out-alt text-blue', class: '', isPartherAccess: true },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  public isPartner = false;
  constructor(private router: Router, private tenantService: TenantService, ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.tenantService.getUserRoles().then(
      data => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].toString().toLowerCase() === 'partner') {
              this.isPartner = true;
              break;
            } else {
              this.isPartner = false;
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // logout
  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
    localStorage.removeItem("userId");
    this.router.navigate(['/login']);
  }
}
