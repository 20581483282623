import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// local imports
import { Tenant, EditTenant, CreateTenant, Partner } from '../../../models/tenant.model';
import { Plan } from '../../../models/plan.model';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(private http: HttpClient) { }

  // get all tenants
  getAllTenants(isRefreshTenants: boolean, getCounts: boolean): Observable<Tenant[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<Tenant[]>(`/api/Tenant/GetTenants?isRefreshTenants=${isRefreshTenants}&getCounts=${getCounts}`, httpOptions);
  }

  // create tenants
  createTenant(createTenant: CreateTenant): Observable<CreateTenant> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<CreateTenant>('/api/Tenant/AddTenant', createTenant, httpOptions);
  }

  // update tenant
  editTenant(updatedTenant: EditTenant): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.put<EditTenant>('/api/Tenant/UpdateTenant', updatedTenant, httpOptions);
  }

  // delete tenant
  deleteTenant(deletedTenant: Tenant): Observable<Tenant> {
    const id = deletedTenant.tenantId
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.put<Tenant>(`/api/Tenant/DeleteTenant/${id}`, deletedTenant, httpOptions);
  }

  // get plans
  getAllPlans(): Observable<Plan[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<Plan[]>('/api/Tenant/GetPlans', httpOptions);
  }

  getTenantUsers(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<Plan[]>('/api/Tenant/GetUsersByTenantId?id=' + id, httpOptions).toPromise();
  }

  getPartners(): Observable<Partner[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<Partner[]>('/api/Tenant/GetPartner', httpOptions);
  }

  getUserRoles() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<any>('/api/Tenant/GetRoles', httpOptions).toPromise();
  }

  GetLicenceItems(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<any>('api/Tenant/GetLicenceItems', httpOptions);
  }

  GetHeadsUpUrl(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<any>('api/Tenant/GetHeadsUpSearchURL', httpOptions);
  }

}
