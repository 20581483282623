import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  status: string;
  addUser: boolean;
  alreadyConfirmed: boolean;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.status = null;
    this.addUser = false;
    this.status = this.route.snapshot.queryParams.status;
    if (this.status === "adduser") {
      this.addUser = true;
    } else if (this.status == "alreadyConfirmed") {
      this.alreadyConfirmed = true;
    }
  }

}
