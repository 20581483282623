import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor{
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //console.log(req);

        return next.handle(req)
        .pipe(
            // handle errors
            catchError((error: HttpErrorResponse) => {
                //console.log(error)
                //return throwError(error.error.message);
                switch (error.status) {
                    case 401: {
                        return throwError(error);
                    }
                    case 402: {
                        return throwError("Your account is inactive. Please get in touch with admin for more information.");
                    }
                    case 403: {
                        return throwError("Your email is not verified. We have sent you a verification email. Please click on the link there to verify your email address.");
                    }
                    case 400: {
                        return throwError("Email must be a valid email address");
                    }
                    case 404: {
                        return throwError("Not found");
                    }
                    case 405: {
                        return throwError("Your password reset link is not valid, or already used. You can reset password using forgot password link on the login page.");
                    }
                    case 406: {
                    return throwError("You don't have access to login.");
                    }
                    case 200: {
                        return throwError("Email sent");
                    }
                    case 500: {
                        return throwError("Something went wrong");
                    }
                    default: {
                        return throwError(error);
                    }
                  }
                
            })
        )
        
    }
    
}
